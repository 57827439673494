"use client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
export const Providers = ({ children }: { children: React.ReactNode }) => {
  const queryClient = new QueryClient();

  if (!queryClient) {
    throw new Error("No QueryClient set, use QueryClientProvider to set one");
  }
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* <ProgressBar
          height="5px"
          color="#c80f1e"
          options={{ showSpinner: false }}
          shallowRouting
        /> */}
    </QueryClientProvider>
  );
};